<!--
 * @Author: 刘康宁 869161591@qq.com
 * @Date: 2022-07-22 09:46:32
 * @LastEditors: YGQ
 * @LastEditTime: 2024-10-09 18:47:02
 * @FilePath: /oneid/src/views/home/Components/AppCard.vue
 * @Description: 工作台APP卡片
-->
<template>
  <div
    class="card flex text-left"
    :class="canLogin == 1 ? 'can-login' : 'cant-login'"
    @click="navigate"
    @click.right.prevent="navigateLocal"
    v-loading="isLoading"s
    element-loading-background="rgba(0, 0, 0, 0.4)"
    :style="{ background: canLogin == 1 ? app.color : '#777' }"
  >
    <div style="z-index: 3">
      <div class="flex row-center">
        <img :src="require(`@/assets/dashboard/${app.id}.webp`)" :alt="app.title" class="icon" />
        <div class="N9 font18 bold">{{ app.title }}</div>
      </div>
      <div class="padding4" />
      <div class="N9 font12 line20">{{ app.note }}</div>
      <div class="card-button font12">进入应用 <i class="el-icon-arrow-right"></i></div>
    </div>
    <div v-if="canLogin == 1" class="bg-icon-mask" :style="{ 'background-image': app.bgColor }" />
    <div v-else class="bg-icon-mask" style="background-image: linear-gradient(37deg, #798184c8 0%, #969fa6d8 100%)" />
    <img class="bg-icon" :src="require(`@/assets/dashboard/${app.id}.webp`)" :alt="app.title" />
  </div>
</template>
<script>
export default {
  props: { app: Object },
  data () {
    return {
      canLogin: false,
      isLoading: false
    }
  },

  mounted () {
    const permission = JSON.parse(localStorage.getItem('user'))?.permission // 获取系统权限
    this.canLogin = permission.find(e => e.system == this.app.id)?.canLogin || this.$ENV == 'local'  // 判断本应用是否有权限登录, 本地环境需要调色所以不判断
  },

  methods: {
    // 点击卡片跳转
    async navigate () {
      if (this.isLoading || this.canLogin != 1) return false
      const ENV = this.$ENV == 'local' ? 'develop' : this.$ENV // 当前环境. 本地环境视同开发环境
      const url =  this.app?.url?.[ENV]?.[localStorage.HOST_CODE] + `?code=${localStorage.HOST_CODE}`
      if (url && this.app.noLogin) {
        window.location.href = url  // 无需 SSO 登录直接跳转
      } else if (url) {
        this.go(url) // 需要 SSO 登录后才能跳转
      }
    },

    //? 若为本地或测试环境, 右键点击跳转到本地环境, 否则不响应
    async navigateLocal () {
      if (this.isLoading || this.canLogin != 1) return false
      const ENV = this.$ENV == 'local' ? 'develop' : this.$ENV // 当前环境. 本地环境视同开发环境   
      if (this.isLoading || ENV != 'develop') return false
      this.go(this.app.url.local) + '?token=' + this.$cookie.get('TOKEN') // 跳转到 local 地址
    },

    // 请求单点登录并跳转
    async go (url) {
      this.isLoading = true
      const [, err] = await this.$post('ssoLogin', { appName: this.app.id }) // 请求单点登录 
      this.isLoading = false
      if (err) return this.$msg(err)
      window.location.href = url + '&token=' + this.$cookie.get('TOKEN')
    }
  }
}
</script>

<style scoped>
.card {
  position: relative;
  width: 278px;
  height: 161px;
  padding: 24px;
  box-sizing: border-box;
  margin: 0 8px 16px 8px;
  color: #ffffff;
  box-shadow: 0px 0px 8px 0px #ecedef;
  overflow: hidden;
  border-radius: 8px;
  transition: all 0.3s;
}
.card:hover {
  box-shadow: 0px 4px 6px 0px #d2d4d5;
}
.icon {
  opacity: 0.9;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.card-button {
  position: absolute;
  bottom: 30px;
  left: 30px;
  border: 1px #ffffff88 solid;
  border-radius: 4px;
  width: auto;
  padding: 8px;
  transition: all 0.3s;
}
.card-button:hover {
  background-color: #ffffff11;
  transform: scale(1.1);
}
.bg-icon-mask {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.bg-icon {
  z-index: 1;
  position: absolute;
  right: -100px;
  bottom: -100px;
  opacity: 0.15;
  width: 300px;
  height: 300px;
  -webkit-filter: blur(2px); /* Chrome, Opera */
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}
/* 有权限应用,悬停时动一下 */
.can-login:hover {
  cursor: pointer;
  transform: translate(0, -5px);
}
.cant-login::before {
  position: absolute;
  left: 0;
  top: 0;
  content: '暂无权限';
  opacity: 0;
  background: #00000066;
  text-align: center;
  width: 278px;
  line-height: 161px;
  font-size: 18px;
  font-weight: 800;
  color: #fff;
  transition: all 0.3s;
  z-index: 4;
}
/* 灰色卡片鼠标悬停提示暂无权限 */
.cant-login:hover::before {
  opacity: 1;
}
</style>